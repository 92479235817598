'use client';

import Logo from '@gds/Logo/Logo';
import { usePathname } from 'next/navigation';
import { TrackedLink } from '../TrackedLink/TrackedLink';
import { DE_LOCALE } from '@core/Constants/Locale.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';

export const LogoWrapper = ({
  baseUrl,
  logoStyle,
  locale,
}: {
  baseUrl: string;
  logoStyle: string;
  locale: Locale;
}) => {
  const pathname = usePathname();

  // TODO: Remove legacy DE url when we have DE migrated completely
  const href = pathname?.includes('reviews') && locale === DE_LOCALE ? 'https://hey.car' : baseUrl;

  return (
    <TrackedLink
      ariaLabel="heycar logo"
      className={logoStyle}
      href={href}
      trackingObj={{ label: 'Logo', href: baseUrl }}
    >
      <Logo />
    </TrackedLink>
  );
};
