'use client';

import { useCallback } from 'react';

import { useAtom, useSetAtom } from 'jotai';

import { Button } from '@gds/Button/Button';
import { Search } from '@gds/Icons/Paths/Search';
import { nlsToggleAtom } from '@core/Components/NaturalLanguageSearch/NaturalLanguageSearchToggle.atom';
import { NaturalLanguageSearch } from '@core/Components/NaturalLanguageSearch/NaturalLanguageSearch';
import { useModal } from '@modal/Hooks/UseModal';

import { validateUrl } from '@core/Utils/Plp/ValidateUrl';
import Grid from '@gds/Grid/Grid';

import { navTrayOpenAtom } from '../../../Atoms/NavTrayOpenAtom';
import { itemOnClick, trackingFunction } from '../../TrackedLink/TrackedLink';

import styles from '../NavButtons.module.css';

type Props = {
  strings: Record<string, string>;
  isDesktopEnabled?: boolean;
};

export const SearchButton = ({ strings, isDesktopEnabled }: Props) => {
  const SearchBar = useCallback(
    () => <NaturalLanguageSearch validateUrl={validateUrl} strings={strings} isPrimaryHeader />,
    [],
  );

  const [toggleSearchBtn, setToogleSearchBtn] = useAtom(nlsToggleAtom);
  const setIsNavTrayOpen = useSetAtom(navTrayOpenAtom);
  const { closeModal } = useModal();

  const toggleSearchBar = () => {
    setToogleSearchBtn?.(!toggleSearchBtn);
    setIsNavTrayOpen(false);
    closeModal();
  };

  return (
    <>
      <Button
        className={styles.item}
        aria-label="Search"
        data-is-desktop-enabled={String(!!isDesktopEnabled)}
        onClick={() =>
          itemOnClick(
            {
              fn: trackingFunction,
              obj: { label: 'Mobile Search Toggle' },
            },
            () => toggleSearchBar(),
          )
        }
      >
        <Search />
      </Button>
      <Grid.Container className={styles.searchBarWrapper}>
        <SearchBar />
      </Grid.Container>
    </>
  );
};
