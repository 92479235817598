'use client';
import { useAtom, useSetAtom } from 'jotai';

import { useModal } from '@modal/Hooks/UseModal';
import { Button } from '@gds/Button/Button';
import { Close } from '@gds/Icons/Paths/Close';
import { Hamburger } from '@gds/Icons/Paths/Hamburger';
import { Typography } from '@gds/Typography/Typography';

import { itemOnClick, trackingFunction } from '../../TrackedLink/TrackedLink';

import styles from '../NavButtons.module.css';

import { navTrayOpenAtom } from '../../../Atoms/NavTrayOpenAtom';
import { nlsToggleAtom } from '@core/Components/NaturalLanguageSearch/NaturalLanguageSearchToggle.atom';

export const BurgerMenuButton = ({
  origin = 'header',
  className,
  iconLabel,
}: {
  origin?: 'header' | 'footer';
  className?: string;
  iconLabel?: string;
}) => {
  const [isNavTrayOpen, setIsNavTrayOpen] = useAtom(navTrayOpenAtom);
  const { closeModal } = useModal();
  const setToogleSearchBar = useSetAtom(nlsToggleAtom);

  const toggleBurgerMenu = () => {
    setIsNavTrayOpen(!isNavTrayOpen);
    setToogleSearchBar(false);
    closeModal();
  };

  return (
    <>
      <Button
        className={`${className ? className : styles.notHorizontalNav} ${
          className ? className : `${styles.item} ${styles.burguerMenu}`
        }`}
        dataTestId="BurgerMenuButton"
        onClick={() =>
          itemOnClick(
            {
              fn: trackingFunction,
              obj: {
                label: 'Burger Menu Toggle',
              },
              origin,
            },
            () => toggleBurgerMenu(),
          )
        }
      >
        {isNavTrayOpen ? <Close /> : <Hamburger />}
        {iconLabel && (
          <Typography variant="caption5" Component="span">
            {iconLabel}
          </Typography>
        )}
      </Button>
    </>
  );
};
